declare const process: { env: { [key: string]: string | undefined } };

export const APP_CONFIG = {
  API_URL: process.env.API_URL,
  API_PREFIX: process.env.API_PREFIX,
  PROXY_PREFIX: process.env.API_PROXY_PREFIX,
  URL_PATH: process.env.URL_PATH,
  ROUTES: {
    FORBIDDEN: "/403",
    SIGN_IN: (process.env.SIGNIN_PATH || "/signin" || "/authorize") as string,
    AUTHORIZE: "/authorize",
    SIGN_UP: "/signup",
    SIGN_UP_SUCCESS: "/signup-success",
    SIGN_UP_CONFIRM: "/signup-confirm",
    EMAIL_CONFIRM: "/email-confirm",
    MAIN: "/",
    LOGOUT: "/logout",
    ABOUT: "/about",
    ADMIN: "/admin",
    PROFILE: "/profile",
    ACCESS_RECOVERY: "/access-recovery",
    ACCESS_RECOVERY_SENT: "/access-recovery-sent",
    RESTORE: "/restore",
    RESTORE_OUTDATED: "/restore-outdated",
    RESTORE_SUCCESS: "/restore-success",
    MAP: "/map/",
    SHARED: "/shared/",
    TOOLS: "/tools",
    AGREEMENTS: {
      path: "/agreements",
      subPaths: {
        userAgreement: "/agreements/user-agreement",
        privacyPolicy: "/agreements/privacy-policy",
        accessibleResources: "/agreements/accessible-resources",
      },
    },
    HELP: {
      path: "/help",
      subPaths: {
        getStarted: "/help/get-started",
        createMap: "/help/create-map",
        addLayer: "/help/add-layer",
        importData: "/help/import-data",
        updateData: "/help/update-data",
        buffers: "/help/buffers",
        availableArea: "/help/available-area",
        geometrySelection: "/help/geometry-selection",
        layerStyle: "/help/layer-style",
        sharing: "/help/sharing",
        queryLanguage: "/help/query-language",
        wmsQgis: "/help/wms-qgis"
      },
    },
    EXTERNAL: {
      path: "/external",
      subPaths: {
        queryLanguage: "/external/query-language",
        wmsQgis: "/external/wms-qgis",
      },
    },
    SOC_AUTH: {
      path: "/account",
      subPaths: {
        vk: "/account/vk",
        google: "/account/google",
        facebook: "/account/facebook",
      },
    },
    SOC_BIND: {
      path: "/bind",
      subPaths: {
        vk: "/bind/vk",
        google: "/bind/google",
        facebook: "/bind/facebook",
      },
    },
  },
  MEDIA: {
    FACEBOOK: "https://facebook.com/everpoint.ru/",
    MEDIUM: "https://medium.com/everpoint",
  },
  CONTACT: {
    SUPPORT_EMAIL: "support@everpoint.ru",
    NOREPLY_EMAIL: "noreply@everpoint.ru",
    INFO_EMAIL: "info@everpoint.ru",
    PHONE: "+7 (495) 762-15-69",
    ADDRESS: "129090, г. Москва, пр-т Мира, д. 19, строение 1, офис 40/1",
  },
} as const;

export const { ROUTES, CONTACT } = APP_CONFIG;
